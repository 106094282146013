import React, { Component } from "react";
import { Col, Row, Form, Button, Alert, Image } from "react-bootstrap";
import { errorHandler } from "../../service/utility";
import { deleteAccount } from "../../api/fetch";
import logonowaste from "../../image/no_waste.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

class DeleteAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      buttondisabled: true,
      confirmDelete: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.buttonEnable = this.buttonEnable.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append("password", this.state.password);
    deleteAccount(this.props.token, formData).then((result) => {
      const isError = errorHandler(result, this.props.history);

      if (isError) {
        this.setState({ validationOldPassword: true });
      } else {
        this.props.closeModal();
        this.props.openModal("successModal", "DELETED");
      }
    });
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  buttonEnable() {
    if (this.state.password.length !== 0) {
      this.setState({ buttondisabled: false });
      return;
    } else {
      this.setState({ buttondisabled: true });
    }
  }

  confirmDelete() {
    this.setState({
      confirmDelete: true,
    });
  }

  render() {
    const { t, closeModal } = this.props;

    if (this.state.confirmDelete) {
      return (
        <div className="p-2 h-100 ">
          <Row className="w-100 justify-content-center m-auto">
            <Col xs={12} md={12} className="cancel">
              <div onClick={closeModal}>
                <p>
                  {t("delete_account_popup.cancel")}{" "}
                  <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                </p>
              </div>
            </Col>

            <Col xs={8} md={7} className=" text-center mt-5">
              <Image
                src={logonowaste}
                className="logonowaste mb-5 w-100 text-center "
                fluid
              />
            </Col>
            <Col xs={8} md={7} className="mb-1  text-center">
              <p className="deleteModalTxt">{t("delete_account_popup.text_first")}<br/>
              {t("delete_account_popup.text_second")}
              </p>
            </Col>

            <Form className="w-100  justify-content-center d-flex flex-column align-items-center ">
              <Col xs={8} md={7} className="my-5  ">
                <p className="mb-1">
                  {t("delete_account_popup.insert_password")}
                </p>
                <Form.Control
                  onKeyUp={this.buttonEnable}
                  type="password"
                  placeholder={t("delete_account_popup.password")}
                  id="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
                {this.state.validationOldPassword ? (
                  <Alert variant="danger" className="mt-3">
                    {t("delete_account_popup.wrong")}
                  </Alert>
                ) : null}
              </Col>
              <Col xs={8} md={5} className="mb-2  text-center">
                <Button
                  disabled={this.state.buttondisabled}
                  variant="danger"
                  type="submit"
                  onClick={this.handleClick}
                  className="w-100"
                >
                  {t("delete_account_popup.delete")}
                </Button>
              </Col>
            </Form>
          </Row>
        </div>
      );
    } else {
      return (
        <div className="p-2 h-100 d-flex">
          <Row className="w-100 justify-content-center m-auto">
            <Col xs={8} md={6} className=" text-center">
              <Image
                src={logonowaste}
                className="logonowaste mb-5 w-100 text-center"
                fluid
              />
            </Col>
            <Col xs={12} md={10} className="mb-2">
              <p className="deleteModalTxt">
                {t("delete_account_popup_confirm.text_first")}
                <br/>
                {t("delete_account_popup_confirm.text_second")}
              </p>
            </Col>
            <Col xs={9} md={4} className="my-4">
              <Button
                className="w-100"
                variant="light"
                type="submit"
                onClick={closeModal}
                size="lg"
              >
                <strong>
                {t("delete_account_popup_confirm.cancel")}
                </strong>
              </Button>
            </Col>
            <Col xs={12} md={12} className="my-5">
              <p onClick={this.confirmDelete} className="confirmDelete mb-5">
                {t("delete_account_popup_confirm.confirm")}
              </p>
            </Col>
          </Row>
        </div>
      );
    }
  }
}

export default DeleteAccount;
