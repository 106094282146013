import AppContainer from './container/AppContainer';
import './App.css';
import { useTranslation } from 'react-i18next';
import { Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function AppComponentLang() {
    const {t} = useTranslation();
    return <AppContainer t={t}/>
}

function App() {
    return (
        <Suspense fallback="loading">
            <div className="App">
                <AppComponentLang/>
            </div>
        </Suspense>
    );
}

export default App;
