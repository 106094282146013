import i18next from "i18next";
import { signup  } from "../api/fetch"
import { errorHandler } from "../service/utility"
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Image,
  Alert,
} from "react-bootstrap";
import logonowaste from "../image/no_waste.png";
import infoicon from "../image/iconinfopass.png";

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      surname: "",
      email: "",
      password: "",
      confirm_password: "",
      language: i18next.language.indexOf("-") === -1?i18next.language.toUpperCase():i18next.language.split("-")[1],
      userCreated: false,
      inputpasstype: "password",
      show: false,
      buttondisabled: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.register = this.register.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.buttonEnable = this.buttonEnable.bind(this);
    this.validateField_name = this.validateField_name.bind(this);
    this.validateField_surname = this.validateField_surname.bind(this);
    this.validateField_email = this.validateField_email.bind(this);
    this.validateField_new = this.validateField_new.bind(this);
    this.validateField_confirm = this.validateField_confirm.bind(this);
  }

  async signup() {
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("surname", this.state.surname);
    formData.append("email", this.state.email);
    formData.append("password", this.state.password);
    formData.append("language", this.state.language);
    formData.append("service_name", "no_waste_imok");

    await signup(formData)
    .then((result) => {
      const isError = errorHandler(result, this.props.history);
      if(!isError) {
        this.props.setT(false, result.token);
        this.props.setN(this.state.name);
        this.setState({ userCreated: true });
      }
    });
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  register(event) {
    event.preventDefault();
    if (this.validateField()) {
      this.signup();
    }
  }

  //Input VALIDATION START

  //Validation for Disabled/Enabled Submit Button
  buttonEnable() {
    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if(this.state.name.length !== 0 && this.state.surname.length !== 0 && passwordRegex.test(this.state.password) && this.state.password === this.state.confirm_password && passwordRegex.test(this.state.password)) {
      this.setState({ buttondisabled: false });
      return;
    }
    else {
      this.setState({ buttondisabled: true });
    }
  }
  //Input Validation name
  validateField_name() {
    document.getElementById("name").classList.remove("error");

    if (this.state.name.length===0) {
      document.getElementById("name").classList.add("error");
    }
  }

  //Input Validation surname
  validateField_surname() {
    document.getElementById("surname").classList.remove("error");

    if (this.state.surname.length===0) {
      document.getElementById("surname").classList.add("error");
    }
  }

  //Input Validation email
  validateField_email() {
    document.getElementById("email").classList.remove("error");
    
    const emailRegex = new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$");
    if(!emailRegex.test(this.state.email)) {
      document.getElementById("email").classList.add("error");
    }
  }

  //Input Validation new password
  validateField_new() {
    document.getElementById("password").classList.remove("error");

    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if(!passwordRegex.test(this.state.password)) {
      document.getElementById("password").classList.add("error");
    }
  }

  //Input Validation confirm password
  validateField_confirm() {
    document.getElementById("password").classList.remove("error");

    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if(!passwordRegex.test(this.state.password)) {
      document.getElementById("password").classList.add("error");
    }

    document.getElementById("confirm_password").classList.remove("error");

    if(this.state.password !== this.state.confirm_password) {
      document.getElementById("password").classList.add("error");
       document.getElementById("confirm_password").classList.add("error");
    }
  }

  //Input Validation
  validateField() {
    for(const error of document.querySelectorAll(".error")) {
      error.classList.remove("error");
    }

    const emailRegex = new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$");
    if(!emailRegex.test(this.state.email)) {
      document.getElementById("email").classList.add("error");
    }
    
    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if (!passwordRegex.test(this.state.password)) {
      document.getElementById("password").classList.add("error");
    }

    if (this.state.password !== this.state.confirm_password) {
      document.getElementById("password").classList.add("error");
      document.getElementById("confirm_password").classList.add("error");
    }

    if (document.getElementsByClassName("error").length !== 0) {
      return false;
    }

    return true;
  }

  //Input VALIDATION END

  //Show Password
  toggleShow() {
    if(this.state.inputpasstype === "password") {
      this.setState({ inputpasstype: "text" });
    }
    else {
      this.setState({ inputpasstype: "password" });
    }
  }

  //Show Password Tips Alert
  setShow(bolshow) {
    this.setState({ show: bolshow });
  }

  render() {
    const { t } = this.props;

    if (this.state.userCreated) {
      return (
        <Container>
          <Row className="justify-content-center ">
            <Col sm={12} md={10} xl={8}>
              <div id="CompleteRegister">
                <h1>{t("signup.title")}</h1>
                <h3>{t("signup.subtitle")}</h3>
                <p className="margin-top">
                  {t("signup.answer")}
                  <Link to="/login">
                    <strong>{t("signup.response")}</strong>
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <Container className="mb-5">
        <Row className="justify-content-center ">
          <Col sm={11} md={9}>
            <Row className="justify-content-center ">
              <Col xs={11} md={11}>
                <Image src={logonowaste} className="logonowaste mb-5" fluid />
                <div className="switch-container ">
                <div className="switch-login">
                  <Link to="/login">{t("switch.login")}</Link>
                  <p>{t("switch.signup")}</p>
                </div>
                </div>
              </Col>
            </Row>
            <Form className="center-form">
              <Col xs={11} md={11}>
                <InputGroup className="mb-3">
                  <Form.Control
                    onKeyUp={this.buttonEnable}
                    type="text"
                    placeholder={t("signup.name")}
                    id="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    onBlur={this.validateField_name}
                  />
                </InputGroup>

                <InputGroup className="mb-3">
                  <Form.Control
                     onKeyUp={this.buttonEnable}
                    type="text"
                    placeholder={t("signup.surname")}
                    id="surname"
                    value={this.state.surname}
                    onChange={this.handleChange}
                    onBlur={this.validateField_surname}
                  />
                </InputGroup>

                <InputGroup className="mb-3">
                  <Form.Control
                   onKeyUp={this.buttonEnable}
                    type="text"
                    placeholder={t("signup.email_address")}
                    id="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    onBlur={this.validateField_email}
                  />
                </InputGroup>
              </Col>
              <div className="rowiconinfo ">
                <Col xs={11} md={11}>
                  {/* PASSWORD */}
                  <InputGroup className="mb-3 inputwithappend">
                    <Form.Control
                      onKeyUp={this.buttonEnable}
                      type={this.state.inputpasstype}
                      placeholder={t("signup.password")}
                      id="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      onBlur={this.validateField_new}
                      
                    />
                    <InputGroup.Append>
                      <Button
                        variant="outline-secondary"
                        onClick={this.toggleShow}
                      >{(this.state.inputpasstype === "password") ? (`${t("signup.show")}`) :  (`${t("signup.hide")}`)}
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
                <Col xs={1} md={1}>
                  <img
                    src={infoicon}
                    className="iconinfo"
                    alt=""
                    onClick={() => this.setShow(!this.state.show)}
                  />
                </Col>
              </div>

              <Col xs={11} md={11}>
                <Alert
                  show={this.state.show}
                  variant="success"
                  onClick={() => this.setShow(false)}
                >
                  <p>{t("signup.password_requirements")}</p>
                </Alert>

                {/* REPEAT PASSWORD */}
                <InputGroup className="mb-3 inputwithappend">
                  <Form.Control
                    onKeyUp={this.buttonEnable}
                    type={this.state.inputpasstype}
                    placeholder={t("signup.confirm_password")}
                    id="confirm_password"
                    value={this.state.confirm_password}
                    onChange={this.handleChange}
                    onBlur={this.validateField_confirm}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="outline-secondary"
                      onClick={this.toggleShow}
                    >{(this.state.inputpasstype === "password") ? (`${t("signup.show")}`) :  (`${t("signup.hide")}`)}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
                {/*Submit */}
                <Button
                  disabled={this.state.buttondisabled}
                  variant="primary"
                  type="submit"
                  onClick={this.register}
                >
                  {t("signup.register")}
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Signup;
