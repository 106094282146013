import React, { Component } from "react";
import { fetchRestaurants, setRestaurant } from "../api/fetch";
import { errorHandler } from "../service/utility";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import { Container, Row, Col, Button, Form, Image } from "react-bootstrap";
// import arrow_l from "../image/chevron_sx.svg";
// import arrow_r from "../image/chevron_dx.svg";
import iconrestaurant from "../image/restaurant.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import logonowaste from "../image/no_waste.png";
Modal.setAppElement("#root");

class Restaurants extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetched: false,
      restaurants: null,
      id: "",
      name: "",
      address: "",
      areYouSureModal: false,
      isActiveUser: false,
    };

    this.areYouSure = this.areYouSure.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.pick = this.pick.bind(this);
  }

  async componentDidMount() {
    const formData = new FormData();
    formData.append("lat", 0);
    formData.append("lon", 0);

    this.setState(
      {
        isActiveUser:
          this.props.history.location.pathname === "/restaurants"
            ? false
            : true,
      },
      async () => {
        await fetchRestaurants(
          this.props.getT(this.state.isActiveUser),
          formData
        ).then((result) => {
          const isError = errorHandler(result, this.props.history);
          if (!isError) {
            this.setState({
              restaurants: result,
              isFetched: true,
            });
          }
        });
      }
    );
  }

  async pick(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append("place_id", this.state.id);

    await setRestaurant(
      this.props.getT(this.state.isActiveUser),
      formData
    ).then((result) => {
      const isError = errorHandler(result, this.props.history);
      if (!isError) {
        if (!this.state.isActiveUser) {
          this.props.setT(true, this.props.getT(false));
        }
        this.props.setR(this.state.name);
        this.props.history.push("/");
      }
    });
  }

  areYouSure(event) {
    event.preventDefault();
    this.setState({
      id: event.target.id,
      name: document
        .querySelector(`[id='${event.target.id}']`)
        .parentElement.querySelector("[id='name']").textContent,
      address: document
        .querySelector(`[id='${event.target.id}']`)
        .parentElement.querySelector("[id='address']").textContent,
      areYouSureModal: true,
    });
  }

  closeModal() {
    this.setState({ areYouSureModal: false });
  }

  render() {
    const { t } = this.props;

    if (!this.state.isFetched) {
      return <span>{t("general.loading")}</span>;
    } else {
      return (
        <Container>
          <Row className="justify-content-center">
            <Col xs={11} md={11}>
              <Image src={logonowaste} className="logonowaste " fluid />
              <div
                className="switch-container "
                style={{
                  visibility: this.state.isActiveUser ? "hidden" : "",
                }}
              >
                <div className="switch-login">
                  <Link to="/login">{t("switch.login")}</Link>
                  <p>{t("switch.signup")}</p>
                </div>
              </div>
            </Col>

            <Col sm={12}>
              <h1>{t("restaurants.title")}</h1>
              <Form>
                <Carousel
                  showIndicators={false}
                  showThumbs={false}
                  swipeable={false}
                  emulateTouch
                  className="carousel_restaurants"
                  autoPlay
                  showStatus={false}
                  centerMode
                  centerSlidePercentage={
                    window.matchMedia("(max-width: 800px)").matches ? 70 : 33
                  }
                >
                  {this.state.restaurants.map((restaurant, index) => {
                    return (
                      <div key={restaurant.id} className="restaurant_container">
                        <img
                          className="iconrestaurant"
                          src={iconrestaurant}
                          alt={"restaurant" + index}
                        />
                        <h3 id="name">{restaurant.name}</h3>
                        <p id="address">{restaurant.address}</p>
                        <Button
                          className="mt-3"
                          variant="light"
                          type="submit"
                          id={restaurant.id}
                          onClick={this.areYouSure}
                        >
                          {t("restaurants.pick")}
                        </Button>
                      </div>
                    );
                  })}
                </Carousel>

                {/* MODAL */}
                <Modal isOpen={this.state.areYouSureModal} className="lowModal">
                  <Row className="justify-content-center row_modal">
                    <Col sm={12}>
                      <h2 className="modal_title">
                        {t("restaurants.title_modal")}
                      </h2>

                      <div className="restaurant_container">
                        <img
                          className="iconrestaurant"
                          src={iconrestaurant}
                          alt={"restaurant"}
                        />
                        <h3 id="name">{this.state.name}</h3>
                        <p id="address">{this.state.address}</p>
                      </div>
                      <div className="button_container mt-4">
                        <Button
                          className="confirm"
                          variant="secondary"
                          type="submit"
                          onClick={this.pick}
                        >
                          {t("restaurants.confirm")}
                        </Button>

                        <Button
                          className="cancel"
                          variant="light"
                          type="submit"
                          onClick={this.closeModal}
                        >
                          {t("restaurants.cancel")}
                        </Button>
                      </div>
                    </Col>{" "}
                  </Row>
                </Modal>
              </Form>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

export default Restaurants;
