import { Component } from "react";
import { changePasswordFromEmail } from "../api/fetch"
import { errorHandler, backToLogin } from "../service/utility"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Alert,
} from "react-bootstrap";
import infoicon from "../image/iconinfopass.png";
class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      new_password: "",
      confirm_password: "",
      buttondisabled: true,
      inputpasstype: "password",
      show: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.confirmPassword = this.confirmPassword.bind(this);
    this.buttonEnable = this.buttonEnable.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.validateField_new = this.validateField_new.bind(this);
    this.validateField_confirm = this.validateField_confirm.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  //Validation for Disabled/Enabled Submit
  buttonEnable() {
    const passwordRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    );
    if (
      this.state.new_password === this.state.confirm_password &&
      passwordRegex.test(this.state.new_password)
    ) {
      this.setState({ buttondisabled: false });
      return;
    } else {
      this.setState({ buttondisabled: true });
    }
  }

  validateField_new() {
    document.getElementById("new_password").classList.remove("error");

    const passwordRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    );
    if (!passwordRegex.test(this.state.new_password)) {
      document.getElementById("new_password").classList.add("error");
    }
  }

  validateField_confirm() {
    document.getElementById("new_password").classList.remove("error");

    const passwordRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    );
    if (!passwordRegex.test(this.state.new_password)) {
      document.getElementById("new_password").classList.add("error");
    }

    document.getElementById("confirm_password").classList.remove("error");

    if (this.state.new_password !== this.state.confirm_password) {
      document.getElementById("new_password").classList.add("error");
      document.getElementById("confirm_password").classList.add("error");
    }
  }

  validateField() {
    for(const error of document.querySelectorAll(".error")) {
      error.classList.remove("error");
    }
    const passwordRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    );
    if(!passwordRegex.test(this.state.new_password)) {
      document.getElementById("new_password").classList.add("error");
    }

    if(this.state.new_password !== this.state.confirm_password) {
      document.getElementById("new_password").classList.add("error");
      document.getElementById("confirm_password").classList.add("error");
    }
    if(document.getElementsByClassName("error").length !== 0) {
      return false;
    }
    return true;
  }

  confirmPassword(event) {
    event.preventDefault();
    if(this.validateField()) {
      this.resetPassword();
    }
  }
  // Show/hide password
  toggleShow() {
    if (this.state.inputpasstype === "password") {
      this.setState({ inputpasstype: "text" });
    } else {
      this.setState({ inputpasstype: "password" });
    }
  }
  // Show/hide Tips Password
  setShow(bolshow) {
    this.setState({ show: bolshow });
  }

  async resetPassword() {
    const formData = new FormData();
    formData.append("new_password", this.state.new_password);
    formData.append("email_token", this.props.location.search.split("=")[1]);

    await changePasswordFromEmail(formData)
    .then((result) => {
      const isError = errorHandler(result, this.props.history);
      if(!isError) {
        backToLogin(result, this.props.history);
      }
    });
  }

  render() {
    const { t } = this.props;

    return (
      <Container>
        <Row className="justify-content-md-center ">
          <Col sm={11} md={9} lg={8} xl={6}>
            <h1>{t("change_password.title")}</h1>
            <Form id="form" className="margin-top center-form">
              <div className="rowiconinfo ">
                <Col xs={11} md={11}>
                  {/* PASSWORD */}
                  <InputGroup className="mb-3 inputwithappend">
                    <Form.Control
                      type={this.state.inputpasstype}
                      placeholder={t("change_password.new_password")}
                      id="new_password"
                      value={this.state.new_password}
                      onChange={this.handleChange}
                      onKeyUp={this.buttonEnable}
                      onBlur={this.validateField_new}
                    />
                    <InputGroup.Append>
                      <Button
                        variant="outline-secondary"
                        onClick={this.toggleShow}
                      >
                        {this.state.inputpasstype === "password"
                          ? `${t("change_password.show")}`
                          : `${t("change_password.hide")}`}
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
                <Col xs={1} md={1}>
                  <img
                    src={infoicon}
                    alt=""
                    className="iconinfo"
                    onClick={() => this.setShow(!this.state.show)}
                  />
                </Col>
              </div>

              <Col xs={11} md={11}>
                <Alert
                  show={this.state.show}
                  variant="success"
                  onClick={() => this.setShow(!this.state.show)}
                >
                  <p>{t("change_password.password_requirements")}</p>
                </Alert>
                <InputGroup className="mb-3 inputwithappend">
                  <Form.Control
                    type={this.state.inputpasstype}
                    placeholder={t("change_password.confirm_password")}
                    id="confirm_password"
                    value={this.state.confirm_password}
                    onChange={this.handleChange}
                    onKeyUp={this.buttonEnable}
                    onBlur={this.validateField_confirm}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="outline-secondary"
                      onClick={this.toggleShow}
                    >
                      {this.state.inputpasstype === "password"
                        ? `${t("change_password.show")}`
                        : `${t("change_password.hide")}`}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
                {/*Submit */}
                <Button
                  disabled={this.state.buttondisabled}
                  variant="primary"
                  type="submit"
                  onClick={this.confirmPassword}
                >
                  {t("change_password.done")}
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ChangePassword;
