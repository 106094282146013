import React, { Component } from "react";
import { login } from "../api/fetch"
import { errorHandler } from "../service/utility"
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Image,
} from "react-bootstrap";
import logonowaste from "../image/no_waste.png";


class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      inputpasstype: "password",
      buttondisabled: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.buttonEnable = this.buttonEnable.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  toggleShow() {
    if (this.state.inputpasstype === "password") {
      this.setState({ inputpasstype: "text" });
    }
    else {
      this.setState({ inputpasstype: "password" });
    }
  }
  async login(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append("email", this.state.email);
    formData.append("password", this.state.password);

    await login(formData)
    .then((result) => {
      const isError = errorHandler(result, this.props.history);
      if(!isError) {
        this.props.setT(true, result.token);
        this.props.setN(result.name);
        this.props.setR(result.restaurant_name);
        this.props.history.push("/");
      }
    });
  }

  //Validation for Disabled/Enabled Submit
  buttonEnable() {
    if (this.state.email.length !== 0 && this.state.password.length !== 0) {
      this.setState({ buttondisabled: false });
      return;
    }
    else {
      this.setState({ buttondisabled: true });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Container  className="mb-5">
        <Row className="justify-content-center ">
          <Col sm={11} md={9}>
            <Row className="justify-content-center ">
              <Col xs={11} md={11}>
                <Image src={logonowaste} className="logonowaste mb-5" fluid />

                <div className="switch-container ">
                  <div className="switch-login ">
                    <p>{t("switch.login")}</p>
                    <Link to="/signup">{t("switch.signup")}</Link>
                  </div>
                </div>
              </Col>
            </Row>
            <Form className="center-form">
              <Col xs={11} md={11}>
                <Form>
                  <InputGroup className="mb-3">
                    <Form.Control
                      onKeyUp={this.buttonEnable}
                      type="email"
                      placeholder={t("login.email_address")}
                      id="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3 inputwithappend">
                    <Form.Control
                      onKeyUp={this.buttonEnable}
                      type={this.state.inputpasstype}
                      placeholder={t("login.password")}
                      id="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                    <InputGroup.Append>
                      <Button
                        variant="outline-secondary"
                        onClick={this.toggleShow}
                      >{(this.state.inputpasstype === "password") ? (`${t("login.show")}`) : (`${t("login.hide")}`)}
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  <Button
                    disabled={this.state.buttondisabled}
                    variant="primary"
                    type="submit"
                    onClick={this.login}
                  >
                    {t("login.login")}
                  </Button>
                </Form>
              </Col>
            </Form>
            <p className="margin-top ">
              {t("login.forgot")}{" "}
              <strong>
                <Link to="/forgot">{t("login.click")}</Link>
              </strong>
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Login;
