import React, { Component } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  Alert,
  Image,
} from "react-bootstrap";
import infoicon from "../../image/iconinfopass.png";
import { errorHandler } from "../../service/utility";
import { changePassword } from "../../api/fetch";
import logonowaste from "../../image/no_waste.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      new_password: "",
      confirm_password: "",
      inputpasstype: "password",
      show: false,
      buttondisabled: true,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateField_new = this.validateField_new.bind(this);
    this.validateField_confirm = this.validateField_confirm.bind(this);
    this.buttonEnable = this.buttonEnable.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append("new_password", this.state.new_password);
    formData.append("old_password", this.state.password);
    changePassword(this.props.token, formData).then((result) => {
      const isError = errorHandler(result, this.props.history);

      if (isError) {
        this.setState({ validationOldPassword: true });
      } else {
        this.props.closeModal();
        this.props.openModal("successModal", "CHANGE");
      }
    });
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
    this.setState({ validationOldPassword: false });
  }

  //Input Validation new password
  validateField_new() {
    document.getElementById("new_password").classList.remove("error");

    const passwordRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    );
    if (!passwordRegex.test(this.state.new_password)) {
      document.getElementById("new_password").classList.add("error");
    }
  }

  //Input Validation confirm password
  validateField_confirm() {
    document.getElementById("new_password").classList.remove("error");

    const passwordRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    );
    if (!passwordRegex.test(this.state.new_password)) {
      document.getElementById("new_password").classList.add("error");
    }

    document.getElementById("confirm_password").classList.remove("error");

    if (this.state.new_password !== this.state.confirm_password) {
      document.getElementById("new_password").classList.add("error");
      document.getElementById("confirm_password").classList.add("error");
    }
  }

  //Validation for Disabled/Enabled Submit Button
  buttonEnable() {
    const passwordRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    );
    if (
      this.state.password.length !== 0 &&
      passwordRegex.test(this.state.new_password) &&
      this.state.new_password === this.state.confirm_password
    ) {
      this.setState({ buttondisabled: false });
      return;
    } else {
      this.setState({ buttondisabled: true });
    }
  }

  toggleShow() {
    if (this.state.inputpasstype === "password") {
      this.setState({ inputpasstype: "text" });
    } else {
      this.setState({ inputpasstype: "password" });
    }
  }

  setShow(bolshow) {
    this.setState({ show: bolshow });
  }

  render() {
    const { t, username, closeModal } = this.props;

    return (
      <div className="p-2 ">
        <Col xs={12} md={12} className="cancel">
          <div onClick={closeModal}>
            <p>
            {t("change_password_popup.cancel")} <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
            </p>
          </div>
        </Col>
        <Row className="w-100">
          <Col xs={8} md={6}>
            <Image src={logonowaste} className="logonowaste mb-5 mt-3" fluid />
          </Col>

          <Col xs={12} md={12} className="mb-2">
            <p>{t("change_password_popup.hi")}{username}{t("change_password_popup.text")}</p>
          </Col>
          <Form className="w-100">
            <Col xs={11} md={6} className="mb-5">
              <strong>{t("change_password_popup.old_password")}</strong>
              <InputGroup className="mb-3 inputwithappend">
                <Form.Control
                  onKeyUp={this.buttonEnable}
                  type={this.state.inputpasstype}
                  placeholder={t("change_password_popup.password")}
                  id="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
                <InputGroup.Append>
                  <Button variant="outline-secondary" onClick={this.toggleShow}>
                    {this.state.inputpasstype === "password"
                      ? `${t("signup.show")}`
                      : `${t("signup.hide")}`}
                  </Button>
                </InputGroup.Append>
              </InputGroup>

              {this.state.validationOldPassword ? (
                <Alert variant="danger">{t("change_password_popup.wrong")}</Alert>
              ) : null}
            </Col>
            <div className="rowiconinfo mb-2">
              <Col xs={11} md={6}>
                <strong>{t("change_password_popup.new_password")}</strong>

                <InputGroup className="mb-3 inputwithappend">
                  <Form.Control
                    onKeyUp={this.buttonEnable}
                    type={this.state.inputpasstype}
                    placeholder={t("change_password_popup.password")}
                    id="new_password"
                    value={this.state.new_password}
                    onChange={this.handleChange}
                    onBlur={this.validateField_new}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="outline-secondary"
                      onClick={this.toggleShow}
                    >
                      {this.state.inputpasstype === "password"
                        ? `${t("signup.show")}`
                        : `${t("signup.hide")}`}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
              <Col xs={1} md={1}>
                <img
                  src={infoicon}
                  className="iconinfo"
                  alt=""
                  onClick={() => this.setShow(!this.state.show)}
                />
              </Col>
            </div>

            <Col xs={11} md={6} className="mb-5">
              <Alert
                show={this.state.show}
                variant="success"
                onClick={() => this.setShow(false)}
              >
                <p>{t("signup.password_requirements")}</p>
              </Alert>
              <InputGroup className="mb-3 inputwithappend">
                <Form.Control
                  onKeyUp={this.buttonEnable}
                  type={this.state.inputpasstype}
                  placeholder={t("change_password_popup.confirm_password")}
                  id="confirm_password"
                  value={this.state.confirm_password}
                  onChange={this.handleChange}
                  onBlur={this.validateField_confirm}
                />
                <InputGroup.Append>
                  <Button variant="outline-secondary" onClick={this.toggleShow}>
                    {this.state.inputpasstype === "password"
                      ? `${t("signup.show")}`
                      : `${t("signup.hide")}`}
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col xs={12} md={12} className="mb-4">
              <Button
                disabled={this.state.buttondisabled}
                variant="primary"
                type="submit"
                onClick={this.handleClick}
              >
                {t("change_password_popup.change")}
              </Button>
            </Col>
          </Form>
        </Row>
      </div>
    );
  }
}

export default ChangePassword;
