import React, { Component } from "react";
import { Link } from "react-router-dom";
import { setAllergenes } from "../api/fetch";
import { errorHandler } from "../service/utility";
import { Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import logonowaste from "../image/no_waste.png";
import {
  celery,
  cereals,
  crustaceans,
  eggs,
  fish,
  lupin,
  milk,
  molluscs,
  mustard,
  nuts,
  peanuts,
  sesame,
  soybeans,
  sulphur,
} from "../image/iconAllergenes";

const allergenes = [
  {
    name: "Gluten",
    value: 1,
    icon: cereals,
  },
  {
    name: "Crustacean",
    value: 2,
    icon: crustaceans,
  },
  {
    name: "Egg",
    value: 3,
    icon: eggs,
  },
  {
    name: "Fish",
    value: 4,
    icon: fish,
  },
  {
    name: "Peanut",
    value: 5,
    icon: peanuts,
  },
  {
    name: "Soya",
    value: 6,
    icon: soybeans,
  },
  {
    name: "Dairy",
    value: 7,
    icon: milk,
  },
  {
    name: "Nut",
    value: 8,
    icon: nuts,
  },
  {
    name: "Celery",
    value: 9,
    icon: celery,
  },
  {
    name: "Mustard",
    value: 10,
    icon: mustard,
  },
  {
    name: "Sesame",
    value: 11,
    icon: sesame,
  },
  {
    name: "Sulphites",
    value: 12,
    icon: sulphur,
  },
  {
    name: "Lupin",
    value: 13,
    icon: lupin,
  },
  {
    name: "Mollusk",
    value: 14,
    icon: molluscs,
  },
];

class Allergenes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allergenes_checked: [],
      buttondisabled: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.done = this.done.bind(this);
    this.buttonEnable = this.buttonEnable.bind(this);
  }

  //Validation for Disabled/Enabled Submit
  buttonEnable() {
    if (document.querySelectorAll("input:checked").length !== 0) {
      this.setState({ buttondisabled: false });
      return;
    } else {
      this.setState({ buttondisabled: true });
    }
  }

  handleChange(event) {
    if (
      event.target.value === "any_allergen" &&
      event.target.checked &&
      document.querySelectorAll("input:not([value='any_allergen']):checked")
    ) {
      for (const checked of document.querySelectorAll(
        "input:not([value='any_allergen']):checked"
      )) {
        checked.checked = false;
      }
    }
    if (
      event.target.value !== "any_allergen" &&
      event.target.checked &&
      document.querySelector("input[value='any_allergen']:checked")
    ) {
      document.querySelector(
        "input[value='any_allergen']:checked"
      ).checked = false;
    }
  }

  async done(event) {
    event.preventDefault();
    const formData = new FormData();
    let lengthFormData = 0;
    for (const checked of document.querySelectorAll("input:checked")) {
      for (const allergen of allergenes) {
        if (checked.id === "any_allergen") {
          this.props.history.push("/restaurants");
        } else if (checked.id === allergen.name) {
          formData.append("ids_allergenes", allergen.value);
        }
        lengthFormData++;
      }
    }
    if (lengthFormData > 0) {
      await setAllergenes(this.props.getT(false), formData).then((result) => {
        const isError = errorHandler(result, this.props.history);
        if (!isError) {
          this.props.history.push("/restaurants");
        }
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Container className="mb-5">
        <Row className="justify-content-center ">
          <Col sm={11} md={9}>
            <Row className="justify-content-center ">
              <Col xs={11} md={11}>
                <Image src={logonowaste} className="logonowaste " fluid />
                <div className="switch-container ">
                  <div className="switch-login">
                    <Link to="/login">{t("switch.login")}</Link>
                    <p>{t("switch.signup")}</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Form>
              <h1>{t("allergenes.title")}</h1>
              <p>
                {t("allergenes.subtitle")}
                <strong>{t("allergenes.subtitle_bold")}</strong>
              </p>
              <div id="Allergenes">
                <Row className="margin-top">
                  {allergenes.map((allergen) => {
                    return (
                      <Col xs={5} md={4} lg={3}className="offset-lg-1 offset-1 offset-md-0">
                        <div
                          key={allergen.value}
                          className="allergenes_checkbox"
                        >
                          <input
                            onClick={this.buttonEnable}
                            type="checkbox"
                            id={allergen.name}
                            onChange={this.handleChange}
                          />
                             <Image src={allergen.icon} />
                          <label htmlFor={allergen.name}>
                            {t(`list_allergenes.${allergen.name}`)}
                          </label>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <div className="container_noallergies">
                <label htmlFor="any_allergen" className="container_checkbox  ">
                  <input
                    onClick={this.buttonEnable}
                    className="checkmark "
                    type="checkbox"
                    id="any_allergen"
                    value="any_allergen"
                    onChange={this.handleChange}
                  />

                  {t("allergenes.any_allergen")}
                  <span class="checkmark"></span>
                </label>
              </div>

              {/*Submit */}
              <Button
                disabled={this.state.buttondisabled}
                variant="primary"
                type="submit"
                onClick={this.done}
                className="my-5"
              >
                {t("allergenes.done")}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Allergenes;
