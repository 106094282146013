import React, { Component } from "react";
import styled from "styled-components";
import logo from "../image/ImokGreen.png";
import { Container, Col, Row, Button } from "react-bootstrap";
import Outside from "../components/Outside";
import { backToLogin } from "../service/utility";
import Modal from "react-modal";
import ChangePassword from "../components/modal/ChangePassword";
import DeleteAccount from "../components/modal/DeleteAccount";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faKey,
  faMapMarker,
  faSignOutAlt,
  faTrash,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import SuccessModal from "../components/modal/SuccessModal";

const WrapperHeader = styled.header`
  margin-bottom: 20px;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-shadow: 0px 4px 10px 1px #00000052;
  position: fixed;
  width: 100%;
  background-color: #f6f6f8;
  z-index: 1;
`;

const WrapperBody = styled.div`
  padding-top: 70px;
  height: 100vh;
  width: 100%;
`;
const Main = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const HiUser = styled.p`
  display: block;
  margin: 20px 12px auto;
  cursor: pointer;
  width: auto;
  float: right;
`;
const Picto = styled.div`
  display: block;
  margin: 12px;
  width: 120px;
  height: 40px;
  background: url(${(props) => props.picto}) no-repeat;
  background-size: contain;
  float: left;
`;

class Private extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPersonalArea: false,
      changePasswordModal: false,
      deleteUserModal: false,
      successModal: false,
      type: "",
    };

    this.logout = this.logout.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.changeRestaurant = this.changeRestaurant.bind(this);
  }

  componentDidMount() {
    if (!this.props.existT(true)) {
      backToLogin(null, this.props.history);
    }
  }

  changeRestaurant() {
    const { history } = this.props;
    history.push("/changerestaurant");
  }

  logout() {
    this.props.logout();
    backToLogin(null, this.props.history);
  }

  openModal(modal, type) {
    this.setState({
      type: type,
    });
    this.setState({
      [modal]: true,
    });
    if (document.getElementById("outside-container")) {
      document.getElementById("outside-container").classList.add("hide");
    }
  }

  closeModal() {
    this.setState({
      changePasswordModal: false,
      deleteUserModal: false,
      successModal: false,
    });
  }

  render() {
    const { children, getN, getT, getR, t, history } = this.props;

    return (
      <Main>
        <WrapperHeader>
          <Container fluid className="align-self-center">
            <Row>
              <Col xs={"auto"}>
                <Picto picto={logo} />
              </Col>
              <Col className="align-self-center">
                <p className="mb-0 text-left my-3 myRestaurantText">
                  <FontAwesomeIcon icon={faMapMarker} className="mr-2" />
                  <span>{getR()} </span>
                  <Button onClick={this.changeRestaurant} size="sm" className="ml-2">
                  {t("private.change_restaurant")}
                  </Button>
                </p>
              </Col>
              <Col>
                <HiUser id="hi-user">
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    className="mr-2 iconUser"
                  />
                  {t("home.hi")}, {getN()}{" "}
                </HiUser>
              </Col>
            </Row>
            <Outside elemClicked="hi-user">
              <div className="personalarea_container">
                <h3>{t("personal_area.title")}</h3>
                <ul>
                  <li onClick={() => this.openModal("changePasswordModal")}>
                    <FontAwesomeIcon icon={faKey} className="mr-2" />
                    {t("personal_area.change_button")}
                  </li>
                  <li onClick={this.logout}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                    {t("personal_area.logout_button")}
                  </li>
                  <li
                    className="red"
                    onClick={() => this.openModal("deleteUserModal")}
                  >
                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                    {t("personal_area.delete_button")}
                  </li>
                </ul>
              </div>
            </Outside>
          </Container>
        </WrapperHeader>
        <WrapperBody>{children}</WrapperBody>

        <Modal isOpen={this.state.changePasswordModal}>
          <ChangePassword
            t={t}
            token={getT(true)}
            username={getN()}
            closeModal={this.closeModal}
            openModal={this.openModal}
          />
        </Modal>
        <Modal isOpen={this.state.deleteUserModal}>
          <DeleteAccount
            t={t}
            token={getT(true)}
            closeModal={this.closeModal}
            openModal={this.openModal}
            history={history}
          />
        </Modal>
        <Modal isOpen={this.state.successModal} className="fullModal">
          <SuccessModal
            t={t}
            closeModal={this.closeModal}
            history={history}
            type={this.state.type}
          />
        </Modal>
      </Main>
    );
  }
}

export default Private;
