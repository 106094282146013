import { fetchApiJWT } from "./model"

function login(data) {
  return fetchApiJWT(`/public/account/login`, "POST", "", data);
}

function signup(data) {
  return fetchApiJWT(`/public/account/sign_in`, "POST", "", data);
}

function confirmAccount(data) {
  return fetchApiJWT(`/public/account/confirm_account`, "POST", "", data);
}

function forgotPassword(data) {
  return fetchApiJWT(`/public/account/forgot_password`, "POST", "", data);
}

function changePasswordFromEmail(data) {
  return fetchApiJWT(`/public/account/change_password_from_email_token`, "POST", "", data);
}

function changePassword(token, data) {
  return fetchApiJWT(`/private/account/change_password`, "POST", token, data);
}

function deleteAccount(token, data) {
  return fetchApiJWT(`/private/account/delete_account_nw`, "POST", token, data);
}

function verifyCodeCompany(token, data) {
  return fetchApiJWT(`/private/account/verify_code_company`, "POST", token, data);
}

function setAllergenes(token, data) {
  return fetchApiJWT(`/private/account/set_allergen`, "POST", token, data);
}

function setRestaurant(token, data) {
  return fetchApiJWT(`/private/account/set_restaurant`, "POST", token, data);
}

function fetchUserDoggyBag(token) {
  return fetchApiJWT(`/private/restaurant/get_user_doggy_bag`, "GET", token, null);
}

function fetchDoggyBags(token) {
  return fetchApiJWT(`/private/restaurant/get_doggy_bags`, "GET", token, null);
}

function setUserDoggyBag(token, data) {
  return fetchApiJWT(`/private/restaurant/set_user_doggy_bag`, "POST", token, data);
}

function fetchRestaurants(token, data) {
  return fetchApiJWT(`/private/restaurant/get_restaurants`, "POST", token, data);
}

export {
  login,
  signup,
  confirmAccount,
  forgotPassword,
  changePasswordFromEmail,
  changePassword,
  deleteAccount,
  verifyCodeCompany,
  setAllergenes,
  setRestaurant,
  fetchUserDoggyBag,
  fetchDoggyBags,
  setUserDoggyBag,
  fetchRestaurants
}