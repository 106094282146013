function backToLogin(result, history) {
    if(result) {
        console.log(result.split(", ")[1].split("=")[1]);
    }
    history.push("/login");
}

function errorHandler(result, history) {
    if(result.detail_code) {
        console.log(result.message);
        switch(result.message) {
            case "ErrorInvalidCodeCompany":
                document.getElementById("code_1").classList.add("error");
                document.getElementById("code_2").classList.add("error");
                document.getElementById("code_3").classList.add("error");
                document.getElementById("code_4").classList.add("error");
                document.getElementById("code_5").classList.add("error");
                document.getElementById("code_6").classList.add("error");
                document.getElementById("invalid_code").classList.add("showerror");
                break;
            case "ErrorEmailNotRegistered":
                document.getElementById("email").classList.add("error");
                break;
            case "ErrorAccountNotActive":
                history.push("/login");
                break;
            case "ErrorPassword":
                for(const error of document.querySelectorAll(".error")) {
                    error.classList.remove("error");
                }
                document.getElementById("password").classList.add("error");
                break;
            case "ErrorEmailRegistered":
                document.getElementById("email").classList.add("error");
                break;
            default:
                break;
        }
        return true
    }
    else if(typeof result === "string" && result.indexOf(" message=") !== -1) {
        backToLogin(result, history);
    }
    else {
        return false
    }
}

export {
    backToLogin,
    errorHandler
}