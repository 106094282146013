import { Component } from "react"
import { confirmAccount } from "../api/fetch"
import { errorHandler } from "../service/utility"

class VerifyEmail extends Component {
  componentDidMount() {
    const token = this.props.location.search.split("=")[1]
    this.confirmAccount(token)
  }
    
  async confirmAccount(token) {
    const formData = new FormData();
    formData.append('token', token);

    await confirmAccount(formData)
    .then((result) => {
      const isError = errorHandler(result, this.props.history);
      if(!isError) {
        this.props.history.push("/authentication");
      }
    });
  }

  render() {
    return(
      null
    )
  }
}

export default VerifyEmail
