import React, { Component } from "react";
import Modal from "react-modal";
import {
  fetchUserDoggyBag,
  fetchDoggyBags,
  setUserDoggyBag,
} from "../api/fetch";
import { errorHandler } from "../service/utility";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import {
  celery,
  cereals,
  crustaceans,
  eggs,
  fish,
  lupin,
  milk,
  molluscs,
  mustard,
  nuts,
  peanuts,
  sesame,
  soybeans,
  sulphur,
} from "../image/iconAllergenes";
import doggyicon from "../image/sacchetto.svg";
import logonowaste from "../image/no_waste.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
  },
};
Modal.setAppElement("#root");

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetched: false,
      existDoggyBagGroups: true,
      alreadyPlacedOrder: false,
      doggy_bag_groups: null,
      areYouSureModal: false,
      sendEmailModal: false,
      id_doggy_bag_group: "",
      title_doggy_bag_group: "",
      doggy_bag_items: null,
    };

    this.fetchDoggyBags = this.fetchDoggyBags.bind(this);
    this.areYouSure = this.areYouSure.bind(this);
    this.closeAreYouSureModal = this.closeAreYouSureModal.bind(this);
    this.order = this.order.bind(this);
    this.redirectHome = this.redirectHome.bind(this);
  }

  async componentDidMount() {
    await fetchUserDoggyBag(this.props.getT(true)).then((result) => {
      const isError = errorHandler(result, this.props.history);
      if (!isError && result) {
        this.setState({ alreadyPlacedOrder: true });
      } else {
        this.fetchDoggyBags();
      }
    });
  }

  async fetchDoggyBags() {
    await fetchDoggyBags(this.props.getT(true)).then((result) => {
      const isError = errorHandler(result, this.props.history);
      if (isError) {
        this.setState({ existDoggyBagGroups: false });
      } else if (result.length === 0) {
        this.setState({ existDoggyBagGroups: false });
      } else {
        this.setState({ doggy_bag_groups: result, isFetched: true });
      }
    });
  }

  areYouSure(event) {
    event.preventDefault();
    for (const item of this.state.doggy_bag_groups) {
      if (item.doggy_bag_group.id.toString() === event.target.id) {
        this.setState({
          id_doggy_bag_group: item.doggy_bag_group.id,
          title_doggy_bag_group: item.doggy_bag_group.title,
          doggy_bag_items: item.doggy_bag_items,
          pick_from: item.doggy_bag_group.doggy_bag_info.pick_from,
          pick_at: item.doggy_bag_group.doggy_bag_info.pick_at,
          areYouSureModal: true,
        });
      }
    }
  }

  sendEmail() {
    this.setState({ sendEmailModal: true });
  }

  async order() {
    const formData = new FormData();
    formData.append("doggy_bag_group_id", this.state.id_doggy_bag_group);
    formData.append("doggy_bag_group_title", this.state.title_doggy_bag_group);
    let listDoggyBagItems = [];
    for (const item of this.state.doggy_bag_items) {
      listDoggyBagItems.push(item.doggy_bag_item.name);
    }
    formData.append("doggy_bag_items_name", listDoggyBagItems);
    formData.append("pick_from", this.state.pick_from);
    formData.append("pick_at", this.state.pick_at);

    await setUserDoggyBag(this.props.getT(true), formData).then((result) => {
      const isError = errorHandler(result, this.props.history);
      if(!isError) {
        this.setState({ sendEmailModal: true });
      }
    });
  }

  closeAreYouSureModal() {
    this.setState({ areYouSureModal: false });
  }

  redirectHome() {
    window.location.reload();
  }

  toStringtoIcon(allergenes) {
    switch (allergenes) {
      case "Gluten":
        return cereals;

      case "Crustacés":
        return crustaceans;

      case "Oeufs":
        return eggs;

      case "Poissons":
        return fish;

      case "Arachides":
        return peanuts;

      case "Soja":
        return soybeans;

      case "Lait":
        return milk;

      case "Fruits à coque":
        return nuts;

      case "Céleri":
        return celery;

      case "Moutarde":
        return mustard;

      case "Sésame":
        return sesame;

      case "Sulfites":
        return sulphur;

      case "Lupin":
        return lupin;

      case "Mollusques":
        return molluscs;

      default:
        return null;
    }
  }

  render() {
    const { t } = this.props;

    if (this.state.alreadyPlacedOrder) {
      return (
        <div id="No_doggy">
          <Container className="h-100">
            <Row>
              <Col sm={12} md={12} className="text-left margin-top">
                <Image
                  src={logonowaste}
                  className="logonowaste margin-top mb-4"
                  fluid
                />
                <p className="lightfont">{t("home.description")}</p>
              </Col>
            </Row>
            <Row className=" text-center  align-items-center justify-content-center">
              <Col sm={11} md={10} className="text-center margin-top ">
                <h1 className="secondary margin-top">
                  {t("home.already_order")}{" "}
                </h1>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (!this.state.existDoggyBagGroups) {
      return (
        <div id="No_doggy">
          <Container className="h-100">
            <Row>
              <Col sm={12} md={12} className="text-left margin-top">
                <Image
                  src={logonowaste}
                  className="logonowaste margin-top  mb-4"
                  fluid
                />
                <p className="lightfont">{t("home.description")}</p>
              </Col>
            </Row>
            <Row className=" text-center  align-items-center justify-content-center">
              <Col sm={11} md={10} className="text-center margin-top ">
                <h1 className="secondary margin-top">
                  {t("home.nobags_title")}{" "}
                </h1>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (!this.state.isFetched) {
      return <span>{t("general.loading")}</span>;
    } else {
      return (
        <div>
          <Container>
            <Row>
              <Col sm={12} md={12} className="text-left margin-top">
                <Image src={logonowaste} className="logonowaste mb-4" fluid />
                <p className="lightfont">{t("home.description")}</p>
              </Col>
            </Row>
            {this.state.doggy_bag_groups.map((item) => {
              return (
                <Row key={item.doggy_bag_group.id} className=" margin-top ">
                  <Col xs={12} sm={6} md={4} className="doggy_left ">
                    <div className="doggy_square">
                      <h2>{item.doggy_bag_group.title}</h2>
                      <img className="img_doggy" src={doggyicon} alt="" />
                      <h4>
                        {" "}
                        {item.doggy_bag_group.doggy_bag_info.quantity}
                        {t("home.item_left")}{" "}
                      </h4>
                    </div>
                    <Button
                      variant="secondary"
                      id={item.doggy_bag_group.id}
                      onClick={this.areYouSure}
                    >
                      {t("home.choose")}{" "}
                    </Button>
                  </Col>

                  <Col xs={12} sm={6} md={8}>
                    <h3 className="text-md-left ">
                      <strong>
                        {" "}
                        {t("home.pickup_between")}
                        {item.doggy_bag_group.doggy_bag_info.pick_from}{" "}
                        {t("home.and")}
                        {item.doggy_bag_group.doggy_bag_info.pick_at}{" "}
                      </strong>
                    </h3>
                    {item.doggy_bag_items === null
                      ? null
                      : item.doggy_bag_items.map((item) => {
                          return (
                            <div key={item.doggy_bag_item.id}>
                              <Row>
                                <Col className="text-left  md_100" xs={12}>
                                  <p>{item.doggy_bag_item.name}</p>
                                  <Row>
                                    <Col className=" ">
                                      {item.allergenes === null
                                        ? null
                                        : item.allergenes.map((item) => {
                                            return (
                                              <img
                                                className="home_icon_allergenes"
                                                alt=""
                                                src={this.toStringtoIcon(
                                                  item.name
                                                )}
                                              />
                                            );
                                          })}{" "}
                                    </Col>
                                    <Col className="calories ">
                                      <p className="text-md-right ">
                                        <strong>
                                          {" "}
                                          {item.doggy_bag_item.calories !== null
                                            ? item.doggy_bag_item.calories
                                            : 0}
                                          {" kcal"}{" "}
                                        </strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col className="labels_container">
                                  {item.labels === null
                                    ? null
                                    : item.labels.map((item) => {
                                        return (
                                          <img
                                            src={item.image}
                                            alt=""
                                            className="image_label"
                                          />
                                        );
                                      })}{" "}
                                </Col>
                              </Row>
                            </div>
                          );
                        })}{" "}
                  </Col>
                </Row>
              );
            })}{" "}
          </Container>
          <Modal isOpen={this.state.areYouSureModal} className="lowModal">
            <Container className="h-100">
              <Row className="text-center">
                <Col xs="12">
                  <h2 className="secondary top_80">{t("home.choose_item")}</h2>
                </Col>
              </Row>
              <Row className=" margin-top justify-content-center padding-bottom">
                <Col xs={12} sm={6} md={4} className="doggy_left ">
                  <div className="doggy_square">
                    <h2>{this.state.title_doggy_bag_group}</h2>
                    <img
                      className="img_doggy"
                      src={doggyicon}
                      alt="doggy Icon"
                    />
                  </div>
                </Col>

                <Col
                  xs={12}
                  sm={6}
                  md="auto"
                  className="d-flex flex-column text-left"
                >
                  <div className="mb-auto">
                    {this.state.doggy_bag_items === null
                      ? null
                      : this.state.doggy_bag_items.map((item) => {
                          return (
                            <p key={item.doggy_bag_item.id}>
                              {item.doggy_bag_item.name}{" "}
                            </p>
                          );
                        })}{" "}
                  </div>
                  <div className="buttons_doggymodal">
                    <Button variant="secondary" onClick={this.order}>
                      {t("home.order")}{" "}
                    </Button>
                    <Button variant="light" onClick={this.closeAreYouSureModal}>
                      {t("home.dismiss")}{" "}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal>

          <Modal
            id="Modal_ordersend"
            isOpen={this.state.sendEmailModal}
            style={customStyles}
          >
            <Container className="h-100" fluid>
              <Col xs={12} md={12} className="cancel">
                <div onClick={this.redirectHome}>
                  <p>
                  <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                  </p>
                </div>
              </Col>
              <Row className="h-100 justify-content-center align-items-center text-center">
                <Col>
                  <Image src={logonowaste} className="logonowaste " fluid />

                  <h2 className="secondary">
                    <strong>{t("home.order_send")}</strong>
                  </h2>
                  <p>
                    <strong>
                      {" "}
                      {t("home.pickup_your")}
                      {this.state.title_doggy_bag_group} {t("home.between")}
                      {this.state.pick_from} {" "}
                      {t("home.and")} {this.state.pick_at}{" "}
                    </strong>
                  </p>
                </Col>
              </Row>
            </Container>
          </Modal>
        </div>
      );
    }
  }
}

export default Home;
