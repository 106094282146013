import React, { Component } from "react";
import logo from "../../image/ImokGreen.png";
import { Col, Container, Button, Image } from "react-bootstrap";
import logonowaste from "../../image/no_waste.png";
import { backToLogin } from "../../service/utility";

class SuccessModal extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    if (event.target.parentNode.id === "login" || event.target.id === "login" ) {
      backToLogin(null, this.props.history);
    } else {
      this.props.closeModal();
    }
  }

  render() {
    const { t } = this.props;

    if (this.props.type === "DELETED") {
      return (
        <div className="p-2 h-100 w-100 modalSuccesDelete">
          <Col xs={11} md={12}>
            <Image src={logo} className="logoGreenImok mb-5" fluid />
          </Col>
          <Container className="mt-5">
            <Col xs={8} md={12}>
              <Image
                src={logonowaste}
                className="logonowaste mb-3 mt-5"
                fluid
              />
            </Col>

            <Col xs={8} md={6} className="mb-3">
              <h3>{t("success_popup.text_delete")}</h3>
              <p className="mt-3">{t("success_popup.sub_text")}</p>
            </Col>

            <Col xs={7} md={3} className="mb-4">
              <Button
                id="login"
                variant="light"
                type="submit"
                className="w-100"
                onClick={this.handleClick}
              >
                <strong>
                {t("success_popup.back_login")}
                </strong>
              </Button>
            </Col>
          </Container>
        </div>
      );
    } else {
      return (
        <div className="p-2 h-100 w-100 modalSuccesPassword">
          <Col xs={11} md={12}>
            <Image src={logo} className="logoGreenImok mb-5" fluid />
          </Col>
          <Container className="mt-5">
            <Col xs={8} md={12}>
              <Image
                src={logonowaste}
                className="logonowaste mb-3 mt-5"
                fluid
              />
            </Col>
            <Col xs={8} md={6} className="mb-5">
              <h3>{t("success_popup.text_change")}</h3>
            </Col>
            <Col xs={7} md={3} className="mb-4">
              <Button
                variant="light"
                type="submit"
                className="w-100"
                onClick={this.handleClick}
              >
                <strong>
                {t("success_popup.back_menu")}
                </strong>
              </Button>
            </Col>
          </Container>
        </div>
      );
    }
  }
}

export default SuccessModal;
