function fetchApiJWT(url, action, token, data) {
  return fetch(`${process.env.REACT_APP_URL_SERVER}${url}`, {
    method: action,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  })
  .then((res) => {
    return res.json();
  })
}

export {
  fetchApiJWT
}