import React, { Component } from "react";
import styled from "styled-components";
import logo from "../image/ImokGreen.png";
import { Container } from "react-bootstrap";
const WrapperHeader = styled.header`
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const WrapperBody = styled.div`
  height: calc(100vh - 70px);
  width: 100%;
  display: flex;
`;

const Content = styled.div`
  padding: 10vh 0;
  min-height: 90vh;
  width: 100%;
  margin: auto;
`;

const Picto = styled.div`
  display: block;
  margin: 12px;
  width: 160px;
  height: 60px;
  background: url(${(props) => props.picto}) no-repeat;
  background-size: contain;
`;

class AlternativePrivate extends Component {
  componentDidMount() {
    if (!this.props.existT(true)) {
      this.props.history.push("/login");
    }
  }

  render() {
    const { children } = this.props;
    return (
      <Container fluid>
        <WrapperHeader>
          <Picto picto={logo} />
        </WrapperHeader>
        <WrapperBody>
          <Content>{children}</Content>
        </WrapperBody>
      </Container>
    );
  }
}

export default AlternativePrivate;
