import React, { Component } from "react";
import { forgotPassword } from "../api/fetch"
import { errorHandler } from "../service/utility"
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";


class Forgot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      send: false,
      buttondisabled: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.buttonEnable= this.buttonEnable.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  async sendEmail(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append('email', this.state.email);
    formData.append('service_name', "no_waste_imok");

    await forgotPassword(formData)
    .then((result) => {
      const isError = errorHandler(result, this.props.history);
      if(isError) {
        for (const error of document.querySelectorAll(".error")) {
          error.classList.remove("error");
        }
      } else {
        this.setState({ send: true });
      }
    });
  }
  //Validation for Disabled/Enabled Submit
  buttonEnable() {
    if (this.state.email.length !== 0 ) {
      this.setState({ buttondisabled: false });
      return;
    } else {
      this.setState({ buttondisabled: true });
    }
  }
  render() {
    const { t } = this.props;

    if (this.state.send) {
      return (
          <Container>
            <Row className="justify-content-md-center ">
              <Col sm={11} md={9}>
                <h1>{t("forgot.title_email")}</h1>
                <h3>{t("forgot.subtitle_email")}</h3>
                <p className="margin-top ">
                {t("forgot.answer_email")}
                <strong>
                  <Link onClick={this.sendEmail}>{t("forgot.response_email")}</Link>
                </strong>
              </p>
              </Col>
            </Row>
          </Container>
      );
    }

    return (
      <Container>
        <Row className="justify-content-md-center ">
          <Col sm={11} md={9} lg={6}>
            <h1>{t("forgot.title")}</h1>
            <Form className="margin-top ">
              <InputGroup className="mb-3">
                <Form.Control
                  type="email"
                  placeholder={t("forgot.email")}
                  id="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  onKeyUp={this.buttonEnable}
                />
              </InputGroup>

              {/*Submit */}
              <Button disabled={this.state.buttondisabled}variant="primary" type="submit" onClick={this.sendEmail}>
                {t("forgot.send")}
              </Button>
              <p className="margin-top ">
                {t("forgot.answer")}
                <strong>
                  <Link to="/login">{t("forgot.response")}</Link>
                </strong>
              </p>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Forgot;
