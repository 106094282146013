import React, { Component } from "react"
import { Router, Route, Switch } from "react-router-dom"
import * as History from "history/"
import * as Layout from "../layouts/index"
import * as Pages from "../pages/index"

const history = History.createBrowserHistory()
const ROUTES = [
  {
    path: "/login",
    component: Pages.Login,
    layout: Layout.Public
  },
  {
    path: "/signup",
    component: Pages.Signup,
    layout: Layout.Public
  },
  {
    path: "/verifyemail",
    component: Pages.VerifyEmail,
    layout: Layout.Temporary
  },
  {
    path: "/authentication",
    component: Pages.Authentication,
    layout: Layout.Temporary
  },
  {
    path: "/allergenes",
    component: Pages.Allergenes,
    layout: Layout.Temporary
  },
  {
    path: "/restaurants",
    component: Pages.Restaurants,
    layout: Layout.Temporary
  },
  {
    path: "/forgot",
    component: Pages.Forgot,
    layout: Layout.Public
  },
  {
    path: "/setnewpassword",
    component: Pages.ChangePassword,
    layout: Layout.Public
  },
  {
    path: "/changerestaurant",
    component: Pages.Restaurants,
    layout: Layout.AlternativePrivate
  },
  {
    path: "/",
    component: Pages.Home,
    layout: Layout.Private
  },
  {
    path: "*",
    component: Pages.Login,
    layout: Layout.Public
  }
]

class AppContainer extends Component {
  setToken(isLogin, token) {
    //for signup
    document.cookie = "tmpTknNw = ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    //for login
    document.cookie = "lgnTknNw = ; expires = Thu, 01 Jan 1970 00:00:00 GMT";

    const expires = new Date();
    if(isLogin) {
      expires.setTime(expires.getTime() + 1 * 3600 * 1000);
      document.cookie = `lgnTknNw=${token}; expires=${expires.toUTCString()}`;
    }  
    else {
      expires.setTime(expires.getTime() + 1 * 3600 * 500);
      document.cookie = `tmpTknNw=${token}; expires=${expires.toUTCString()}`;
    }
  }

  getToken(isLogin) {
    if(isLogin && document.cookie.indexOf("lgnTknNw") !== -1) {
      return document.cookie.split('; ').find(row => row.startsWith('lgnTknNw')).split('=')[1];
    }
    else if(!isLogin && document.cookie.indexOf("tmpTknNw") !== -1) {
      return document.cookie.split('; ').find(row => row.startsWith('tmpTknNw')).split('=')[1];
    }
  }

  existToken(isLogin) {
    if((isLogin && document.cookie.indexOf("lgnTknNw") !== -1) || (!isLogin && document.cookie.indexOf("tmpTknNw") !== -1)) {
      return true;
    }
    else {
      return false;
    }
  }

  setName(name) {
    localStorage.setItem("nameNw", name);
  }

  getName() {
    return localStorage.getItem("nameNw");
  }

  setRestaurant(name) {
    localStorage.setItem("restaurantNw", name);
  }

  getRestaurant() {
    return localStorage.getItem("restaurantNw");
  }

  logout() {
    localStorage.clear();
    document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
  }

  render() {
    return (
      <Router history={history}>
        <div>
          <Switch>
            {ROUTES.map(
              ({ path, component: Comp, layout: Layout }) => {
                return (
                  <Route
                    path={path}
                    key={path}
                    render={props => {
                      const layout = (
                        <Layout {...this.props} {...props} existT={this.existToken} getT={this.getToken} getN={this.getName} getR={this.getRestaurant} logout={this.logout}>
                          <Comp 
                            {...this.props} 
                            {...props} 
                            setN={path==="/login" || path==="/signup" ? this.setName : null} 
                            setT={path==="/login" || path==="/signup" || path==="/restaurants" ? this.setToken : null}
                            setR={path==="/login" || path==="/restaurants" || path==="/changerestaurant" ? this.setRestaurant : null}
                            getT={this.getToken}
                          />
                        </Layout>
                      )

                      return (
                        <div>
                          {layout}
                        </div>
                      )
                    }}
                  />
                )
              }
            )}
          </Switch>
        </div>
      </Router>
    )
  }
}

export default AppContainer
