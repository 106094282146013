import React, { Component } from "react";
import { verifyCodeCompany  } from "../api/fetch"
import { errorHandler } from "../service/utility"
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Image
} from "react-bootstrap";
import logonowaste from "../image/no_waste.png";


class Authentication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      code_1: "",
      code_2: "",
      code_3: "",
      code_4: "",
      code_5: "",
      code_6: "",
      show: false,
      buttondisabled: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.next = this.next.bind(this);
    this.buttonEnable = this.buttonEnable.bind(this);
    this.movetoNext = this.movetoNext.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value }, () => {
      this.setState({
        code:
          this.state.code_1 +
          this.state.code_2 +
          this.state.code_3 +
          this.state.code_4 +
          this.state.code_5 +
          this.state.code_6,
      });
    });
  }
  //Show Password Tips Alert
  setShow(bolshow) {
    this.setState({ show: bolshow });
  }

  //Validation for Disabled/Enabled Submit
  buttonEnable() {
    if (this.state.code.length === 6) {
      this.setState({ buttondisabled: false });
      return;
    } else {
      this.setState({ buttondisabled: true });
    }
  }
  //Focus next input
  movetoNext(event) {
    if (event.target.id.length > 0) {
      document.getElementById(event.target.id).nextSibling.focus();
    }
  }

  async next(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append("code", this.state.code);

    await verifyCodeCompany(this.props.getT(false), formData)
    .then((result) => {
      const isError = errorHandler(result, this.props.history);
      if(!isError) {
        this.props.history.push("/allergenes");
      }
    });
  }

  render() {
    const { t } = this.props;

    return (
      <Container>
        <Row className="justify-content-center ">
          <Col sm={11} md={9}>
            <Row className="justify-content-center ">
              <Col xs={11} md={11}>
                <Image src={logonowaste} className="logonowaste " fluid />

                <div className="switch-container ">
                  <div className="switch-login ">
                    <p>{t("switch.login")}</p>
                    <Link to="/signup">{t("switch.signup")}</Link>
                  </div>
                </div>
              </Col>
            </Row>
            <h1>{t("authentication.title")}</h1>
            <p>{t("authentication.subtitle")}<strong>{t("authentication.subtitle_bold")}</strong></p>
           
          </Col>
          <Form>
            <div id="Autentication">
              <Form.Control
                maxlength="1"
                type="text"
                id="code_1"
                value={this.state.code_1}
                onChange={this.handleChange}
                onKeyUp={this.buttonEnable}
                onKeyPress={this.movetoNext}
              />
              <Form.Control
                maxlength="1"
                type="text"
                id="code_2"
                value={this.state.code_2}
                onChange={this.handleChange}
                onKeyUp={this.buttonEnable}
                onKeyPress={this.movetoNext}
              />
              <Form.Control
                maxlength="1"
                type="text"
                id="code_3"
                value={this.state.code_3}
                onChange={this.handleChange}
                onKeyUp={this.buttonEnable}
                onKeyPress={this.movetoNext}
              />
              <Form.Control
                maxlength="1"
                type="text"
                id="code_4"
                value={this.state.code_4}
                onChange={this.handleChange}
                onKeyUp={this.buttonEnable}
                onKeyPress={this.movetoNext}
              />
              <Form.Control
                maxlength="1"
                type="text"
                id="code_5"
                value={this.state.code_5}
                onChange={this.handleChange}
                onKeyUp={this.buttonEnable}
                onKeyPress={this.movetoNext}
              />
              <Form.Control
                maxlength="1"
                type="text"
                id="code_6"
                value={this.state.code_6}
                onChange={this.handleChange}
                onKeyUp={this.buttonEnable}
              />
            </div>
            <Button
              variant="primary"
              type="submit"
              disabled={this.state.buttondisabled}
              onClick={this.next}
            >
              {t("authentication.next")}
            </Button>
             <p id="invalid_code">{t("authentication.error_validation")}</p>
          </Form>
         
        </Row>
      </Container>
    );
  }
}

export default Authentication;
